.grid__item, .grid__item--picture {
    height: 340px;
    border-radius: 10px;
}

.grid__item {
    position: relative;
    width: 30%;
    background-color: black;
    overflow: hidden;
}

.grid__item--picture {
    width: 100%;
    object-fit: cover;
    cursor: pointer;
    transition: 180ms ease-out;
}

.grid__item--picture:hover {
    opacity: 0.85;
}

.grid__item--title {
    position: absolute;
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    margin: 0;
    padding: 0 0.7rem;
    bottom: 0rem;
    pointer-events: none;
    color: white;
    font-size: 1.05rem;
    background: linear-gradient(0deg, rgba(0,0,0,.5) 0%, rgba(0,0,0,.2) 60%, rgba(0,0,0,0.0) 100%);
}

@media screen and (max-width: 992px) {
    .grid__item {
        width: 47%;
    }
}

@media screen and (max-width: 768px) {
    .grid__item {
        width: 100%;
    }
}