.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin-inline: auto;
    padding: 2em;
}

#navbar__logo {
    width: 200px;
}

.navbar__buttons {
    list-style-type: none;
    display: flex;
    gap: 5rem;
    font-weight: 500;
    font-size: 1.4rem;
}

.navbar__buttons a {
    text-decoration: none;
    color: #FF6060;
}

.navbar__buttons a:hover {
    text-decoration: underline;
}

.navbar__buttons a.active {
    text-decoration: underline;
}


@media screen and (max-width: 768px) {  
    .navbar {
        padding-inline: 1em;
    }

    #navbar__logo {
        width: 100px;
    }

    .navbar__buttons {
        gap: 2rem;
        font-weight: 500;
        font-size: 1.1rem;
    }
}