.collapser {
    margin-bottom: 1em;
    background-color: #eee;
    border-radius: .7em;
}

.collapser__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fb6060;
    color: white;
    padding: .5em 1em;
    border-radius: .7em;
    font-weight: 500;
    font-size: 1.1rem;
    cursor: pointer;
    overflow: hidden;
}

.collapser ul {
    list-style-type: none;
    padding: 0;
}

.collapser__content, .collapser__content.hidden {
    transition: max-height 250ms ease-in-out;
}

.collapser__content {
    max-height: 300px;
    overflow: hidden;
    line-height: 1.5;
}

.collapser__content p, .collapser ul {
    margin: 0;
    padding: .7em 1em;
}

.hidden {
    /* transform: translateY(-110%); */
    max-height: 0;
}

.fa-chevron-down {
    transition: all .5s ease-out;
}

.fa-chevron-down.active {
    transform: rotateX(180deg);
}