.banner {
    position: relative;
    display: grid;
    place-items: center;
    margin: 2em auto 4em;
    height: 8em;
    border-radius: 1.5em;
    color: white;
    background-color: #444;
    overflow: hidden;
}

.banner img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: .7;
}

.banner h1 {
    position: absolute;
}

@media screen and (max-width: 992px) {
    .banner {
        margin: 0 0 2em;
        height: 7em;
        border-radius: 1em;
    }

    .banner h1 {
        padding-left: 1rem;
        font-size: 1.2rem;
        white-space: pre;
        place-self: center start;
    }
}