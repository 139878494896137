.apartment-header, .owner__container, .apartment__owner {
    display: flex;
}

.apartment-header {
    justify-content: space-between;
    margin-bottom: 1em;
}

.apartment__owner {
    gap: 1em;
    margin-bottom: .8em;
}

.title__container h1, .title__container h2 {
    font-weight: 500;
    margin: 0;
}

.title__container h1 {
    font-size: 2rem;
    margin-bottom: .3em;
}

.title__container h2 {
    font-size: 1.2rem;
}

.apartments__tags {
    list-style-type: none;
    display: flex;
    padding: 0;
    flex-wrap: wrap;
    gap: 1rem;
}

.apartments__tags li {
    min-width: 8em;
    background-color: #FF6060;
    padding: .2em 1em;
    border-radius: .7rem;
    text-align: center;
    font-size: 0.9rem;
    color: white;
}

.owner__container {
    flex-direction: column;
}

.owner__name {
    display: grid;
    place-content: center;
    text-align: right;
}

.owner__name h3 {
    margin: 0;
}

.apartment__owner {
    justify-content: end;
}

.apartment__owner-picture {
    height: 64px;
    width: 64px;
}

.apartment__owner-picture img {
    width: 100%;
    border-radius: 50%;
}

.apartment__rating {
    display: flex;
    justify-content: flex-end;
    gap: .6em;
}

.stars {
    font-size: 1.5rem;
}

.rating__star--off {
    color: #ddd;
}

@media screen and (max-width: 992px) {
    .apartment-header {
        flex-direction: column;
    }

    .title__container h1 {
        font-size: 1.4rem;
        margin-bottom: .3em;
    }

    .title__container h2 {
        font-size: 1.1rem;
    }

    .apartments__tags li {
        min-width: 8em;
        padding: .2em 1em;
        font-size: 0.8rem;
    }

    .owner__container {
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
    }

    .apartment__rating {        
        gap: .4em;
    }
    
    .stars {
        font-size: 1.05rem;
    }

    .owner__name h3 {
        font-size: 0.8rem;
        margin: 0;
    }

    .apartment__owner {
        margin-bottom: 0;
    }
    
    .apartment__owner-picture {
        height: 32px;
        width: 32px;
    }
}