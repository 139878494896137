.carousel {
    position: relative;
    margin: 2em auto 4em;
    height: 30em;
    border-radius: 1.5em;
    color: white;
    background-color: #ddd;
    overflow: hidden;
}

.carousel img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.carousel__controls{
    position: relative;
    display: grid;
    place-items: center;
    height: 100%;
    width: 100%;
}

button {
    position: absolute;
    background-color: transparent;
    border: 0;
    cursor: pointer;
}

.btn-prev {
    left: 1em;
}

.btn-next {
    right: 1em;
}

.fa-chevron-left, .fa-chevron-right {
    font-size: 5rem;
    color: rgba(255, 255, 255, .8);
}

.slider-count {
    position: absolute;
    font-size: 1.5rem;
    bottom: 1rem;
}

@media screen and (max-width: 992px) {
    .carousel {
        height: 15em;
        border-radius: 1em;
    }

    .fa-chevron-left, .fa-chevron-right {
        font-size: 2.5rem;
    }

    .slider-count {
        font-size: 1rem;
    }
}