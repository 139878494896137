.error-message {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.error-message h1, .error-message h2 {
    margin: 0;
}

.error-message h1 {
    font-size: 15rem;
}

.error-message h2 {
    font-size: 1.8rem;
    font-weight: 500;
}

.error-message a {
    position: absolute;
    bottom: 2em;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.5em;
    color: #fb6060;
}