.apartment-details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5em;
}

@media screen and (max-width: 992px) {
    .apartment-details {
        grid-template-columns: 1fr;
        gap: 1em;
    }
}