footer {
    display: grid;
    place-items: center;
    height: 120px;
    padding: 1em;
    background-color: black;
    color: white;
}

.footer__logo {
    width: 100px;
}

.footer__text {
    margin: 0;
}

